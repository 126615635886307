import http from '~/utils/http'

export class PlayerApi {
  // @POST('/jav/player/collect')
  // Future<bool> collect(@Body() VideoCollectParam param);
  static collect(params: VideoCollectParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/player/collect',
      body: params
    })
  }

  // @POST('/jav/player/complete')
  // Future<bool> complete(@Body() IdParam param);
  static complete(params: IdParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/player/complete',
      body: params
    })
  }

  // @POST('/jav/player/query')
  // Future<VideoExtend> query(@Body() VideoParam param);
  static query(params: VideoParam, opt?: HttpOptions) {
    return http.post<VideoExtend>({
      ...opt,
      url: '/jav/player/query',
      body: params
    })
  }

  // @POST('/jav/player/relatedGuessVideo')
  // Future<RelateGuessVideo> relatedGuessVideo(@Body() IdParam param);
  static relatedGuessVideo(params: IdParam, opt?: HttpOptions) {
    return http.post<RelateGuessVideo>({
      ...opt,
      url: '/jav/player/relatedGuessVideo',
      body: params
    })
  }

  // @POST('/jav/player/skipPreview')
  // @Extra({'silent': true})
  // Future<SkipPreview> skipPreview(@Body() VideoParam param);
  static skipPreview(params: VideoParam, opt?: HttpOptions) {
    return http.post<SkipPreview>({
      ...opt,
      url: '/jav/player/skipPreview',
      body: params
    })
  }

  // @POST('/jav/player/useCoin')
  // Future<UseCoin> useCoin(@Body() VideoParam param);
  static useCoin(params: VideoParam, opt?: HttpOptions) {
    return http.post<UseCoin>({
      ...opt,
      url: '/jav/player/useCoin',
      body: params
    })
  }

  // @POST('/jav/player/useTickets')
  // Future<UseCoupon> useTickets(@Body() VideoParam param);
  static useTickets(params: VideoParam, opt?: HttpOptions) {
    return http.post<UseCoupon>({
      ...opt,
      url: '/jav/player/useTickets',
      body: params
    })
  }

  // @POST('/jav/player/getCollectStatus')
  // Future<CollectStatus> getCollectStatus(@Body() VideoParam param);
  static getCollectStatus(params: VideoParam, opt?: HttpOptions) {
    return http.post<CollectStatus>({
      ...opt,
      url: '/jav/player/getCollectStatus',
      body: params
    })
  }

  // @POST('/jav/player/videoPlay')
  // Future<bool> videoPlay(@Body() VideoPlayParam param);
  static videoPlay(params: VideoPlayParam, opt?: HttpOptions) {
    return http.post<boolean>({
      ...opt,
      url: '/jav/player/videoPlay',
      body: params
    })
  }
}
